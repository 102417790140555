<template>
  <div class="view-project">
    <h2>Nouveau projet: <span>{{project.name}}</span></h2>
    <project-form />
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import ProjectForm from './ProjectForm.vue';

export default {
  components: { ProjectForm },
  computed: {
    ...mapState('projects', [
      'project',
    ]),
  },
  methods: {
    ...mapActions('projects', [
      'loadProjects',
      'useProject',
    ]),

  },
  mounted() {
    this.useProject();
  },

};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

h2 span {
  @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d))
}

nav {
  a {
    display: inline-block;
    text-decoration: none;
    padding: .5em 1em;
    outline: none;
    color: #333;
    border-radius: 1rem;
    transition: all .2 ease;
  }
  .router-link-active {
    background: linear-gradient(135deg,#444, #222);
    color: #fff;
  }
  margin-bottom: 1rem;
}

.progress {
  display: flex;

  .step::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid #555;
    background: #eee;
  }
}
</style>
